<template>
  <div class="mt-10">
    <div class="flex flex-col gap-3">
      <div class="flex justify-between">
        <label for="answers" class="text-sm font-medium text-gray-700 flex">Answers</label>
      </div>

      <div v-for="(answer, index) in question.variant_attributes.answers_attributes" :key="index" class="flex flex-col gap-3 bg-gray-100 rounded-lg p-3">
        <div class="flex flex-col gap-2">
          <label class="text-sm font-medium text-gray-700 flex">Text</label>
          <textarea
            type="text"
            v-model="answer.text"
            @input="$emit('onInput')"
            class="border border-gray-300 rounded-md p-2"
          />
        </div>

        <div class="flex flex-col gap-2">
          <label class="text-sm font-medium text-gray-700 flex">Estimated percentage</label>
          <input
            type="number"
            v-model="answer.static_percentage"
            @input="$emit('onInput')"
            class="border border-gray-300 rounded-md p-2"
          />
        </div>
      </div>

      <button type="button" @click="addAnswer" class="py-4 px-5 mb-2 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-100">
        Add answer
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  question: {
    type: Object,
    required: true
  }
})

const addAnswer = () => {
  props.question.variant_attributes.answers_attributes = [
    ...props.question.variant_attributes.answers_attributes,
    {
      text: "",
      static_percentage: 0
    }
  ]
}
</script>
