export const QUESTION_VARIANTS = {
  poll: {
    label: 'Poll',
    icon: 'PresentationChartBarIcon',
    background_color: "bg-yellow-100",
    text_color: "text-yellow-800"
  },
  quiz: {
    label: 'Quiz',
    icon: 'CheckCircleIcon',
    background_color: "bg-green-100",
    text_color: "text-green-800"
  },
  // sortable: {
  //   background: "bg-indigo-100",
  //   text: "text-indigo-800"
  // },
  open: {
    label: 'Open',
    icon: 'PencilIcon',
    background_color: "bg-indigo-100",
    text_color: "text-indigo-800"
  }
}
