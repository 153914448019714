<template>
  <form @submit.prevent="submitForm">
    <div>
      <label for="text" class="text-sm font-medium text-gray-700 flex">
        Text
      </label>

      <textarea
        v-model="question.text"
        @input="debouncedSubmit"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>

    <component
      :is="variantComponent(question.variant_name)"
      :question="question"
      @on-input="debouncedSubmit"/>

    <div v-if="errorMessage" class="text-red-500 text-sm">{{ errorMessage }}</div>
  </form>
</template>

<script>
import { ref, inject, computed } from 'vue'
import debounce from 'debounce'
import { VariantOpenFields, VariantPollFields, VariantQuizFields } from './'
import { useQuestionHelpers, useBlockUpdate } from '@/features/slides_editor/composables'

const VARIANT_TO_COMPONENT = {
  open: "VariantOpenFields",
  poll: "VariantPollFields",
  quiz: "VariantQuizFields"
}

export default {
  name: 'QuestionForm',
  props: {
    question: {
      type: Object,
      required: true
    },
    block: {
      type: Object,
      required: true
    }
  },
  components: {
    VariantOpenFields,
    VariantPollFields,
    VariantQuizFields
  },
  setup({ question }) {
    const editor = ref(null)
    const errorMessage = ref('')
    const { onAfterCreate } = useQuestionHelpers()
    const slides = inject('parsedSlides')
    const currentSlide = inject('currentSlide')

    const block = computed(() => {
      return currentSlide.value.blocks.find(b => b.block_type === 'section')
    })

    const { onBlockUpdate } = useBlockUpdate(block.value)

    const submitForm = async () => {
      const sectionParams = {
        id: block.value.section.id,
        questions_attributes: block.value.section.questions
      }

      const response = await onBlockUpdate(sectionParams, 'section_attributes')

      if (response && !question.id) {
        onAfterCreate(slides, response)
      }
    }

    const debouncedSubmit = debounce(submitForm, 500)
    const variantComponent = (variantType) => VARIANT_TO_COMPONENT[variantType.toLowerCase()]

    return {
      editor,
      debouncedSubmit,
      variantComponent,
      errorMessage
    }
  }
}
</script>

<style>
  textarea {
    field-sizing: content;
  }
</style>
