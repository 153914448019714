<template>
  <div class="flex flex-col text-sm justify-center relative items-center" data-type="section">
    <div class="h3 font-extrabold z-200 text-center min-w-[400px] mb-2" ref="blockRef">
      <ContentEditor
        :content="section.title"
        :onUpdate="(html) => onBlockUpdate(html, 'section_title')"
        forcedTag="h3"
        :is-thumbnail="isThumbnail"
        class="before:justify-center"
      />
    </div>

    <div class="p w-2/3 font-light z-200 text-center" style="margin: 15px 0 30px 0;">
      <ContentEditor
        :content="section.subtitle"
        :onUpdate="(html) => onBlockUpdate(html, 'section_subtitle')"
        forcedTag="div"
        class="before:justify-center"
        :is-thumbnail="isThumbnail"
      />
    </div>
  </div>

  <div class="flex flex-wrap justify-center pt-10 pb-20 relative" data-questions ref="questionsContainer">
    <Question
      v-for="(question) in section.questions"
      :key="question.id"
      :question="question"
      :block="block"
    />
  </div>
</template>

<script>
import Sortable from "sortablejs"
import { ref, onMounted } from 'vue'
import Question from '../questions/Question.vue'
import ContentEditor from '../../editor/ContentEditor.vue'
import { useContentEditor } from '../../../composables/useContentEditor'
import { useBlockUpdate } from '../../../composables/useBlockUpdate'

export default {
  name: 'Section',
  props: {
    section: {
      type: Object,
      required: true
    },
    block: {
      type: Object,
      required: true
    },
    isThumbnail: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Question,
    ContentEditor
  },
  setup(props) {
    const { setCurrentEditor, updateEditor, blockRef } = useContentEditor(props.block)
    const { onBlockUpdate } = useBlockUpdate(props.block)

    const questionsContainer = ref(null)

    onMounted(() => {
      new Sortable(questionsContainer.value, {
        animation: 150,
        onEnd: ({ oldIndex, newIndex }) => {
          const questions = props.section.questions
          const question = questions[oldIndex]

          questions.splice(oldIndex, 1)
          questions.splice(newIndex, 0, question)

          props.section.questions = questions.map((question, index) => ({
            ...question,
            position: index + 1
          }))

          const sectionParams = {
            id: props.section.id,
            questions_attributes: props.section.questions
          }

          onBlockUpdate(sectionParams, 'section_attributes')
        },
      })
    })

    return {
      onBlockUpdate,
      updateEditor,
      setCurrentEditor,
      blockRef,
      questionsContainer,
    }
  }
}
</script>
