<template>
  <div class="flex flex-col gap-2">
    <div v-for="(variant, key) in questionVariants" :key="key">
      <button @click="onAddQuestion(key)" class="w-full p-2 rounded-md flex flex-col justify-center items-center gap-2 hover:bg-opacity-50" :class="[variant.background_color, variant.text_color]">
        <component v-if="variant.icon" :is="icons[variant.icon]" class="w-6 h-6" />
        Add {{ variant.label }} question
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import * as icons from "@heroicons/vue/24/outline"
import { QUESTION_VARIANTS } from '@/features/slides_editor/config/constants/question_variants'
import { useQuestionHelpers } from '@/features/slides_editor/composables'
import { useSidePanelHelpers } from '@/features/slides_editor/composables/useSidePanelHelpers'


const props = defineProps({
  slide: {
    type: Object,
    required: true
  }
})

const { hydrateNewQuestion } = useQuestionHelpers()
const { pushToStack } = useSidePanelHelpers()
const questionVariants = computed(() => {
  return QUESTION_VARIANTS
})

const onAddQuestion = (variant) => {
  const block = props.slide.blocks.find(block => block.block_type === 'section')
  const newQuestion = hydrateNewQuestion({ variant_type: variant }, block)

  block.section.questions.push(newQuestion)

  pushToStack({
    content: {
      component: 'QuestionForm',
      title: 'Add Question',
      props: {
        question: newQuestion,
        block: block
      }
    }
  })
}
</script>
