<template>
  <div class="mt-3">
    <label for="example_answer" class="text-sm font-medium text-gray-700 flex">
      Example Answer
    </label>
    <textarea
      v-model="question.variant_attributes.example_answer"
      @input="$emit('onInput')"
      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    />
  </div>
</template>

<script>
  export default {
    name: "VariantOpenFields",
    props: {
      question: {
        type: Object,
        required: true
      }
    }
  }
</script>
