<template>
  <div class="flex flex-col gap-3 my-10">
    <label for="answers" class="text-sm font-medium text-gray-700 flex">Answers</label>

    <div v-for="(answer, index) in question.variant_attributes.answers_attributes" :key="index" class="flex flex-col gap-3 bg-gray-100 rounded-lg p-3">
      <div class="flex flex-col gap-2">
        <label class="text-sm font-medium text-gray-700 flex">Text</label>
        <textarea
          type="text"
          v-model="answer.text"
          @input="$emit('onInput')"
          class="border border-gray-300 rounded-md p-2"
        />
      </div>

      <div class="flex items-center">
        <input
          type="checkbox"
          class="w-4 h-4 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
          @change="$emit('onInput')"
          v-model="answer.correct"
        >
        <label class="ms-2 text-sm font-medium text-gray-700">Correct</label>
      </div>

      <div class="flex flex-col gap-2" v-if="answer.correct">
        <label class="text-sm font-medium text-gray-700 flex">Explanation</label>
        <textarea
          type="text"
          v-model="answer.explanation"
          @input="$emit('onInput')"
          class="border border-gray-300 rounded-md p-2"
        />
      </div>
    </div>

    <button type="button" @click="addAnswer" class="py-4 px-5 mb-2 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-100">
      Add answer
    </button>
  </div>
</template>

<script>
  export default {
    name: "VariantQuizFields",
    props: {
      question: {
        type: Object,
        required: true
      }
    },
    watch: {
      question: (q) => {
        console.log(q)
      }
    },
    setup({ question }) {
      const addAnswer = () => {
        question.variant_attributes.answers_attributes = [
          ...question.variant_attributes.answers_attributes,
          {
            text: "",
            correct: false,
            explanation: ""
          }
        ]
      }

      return {
        addAnswer
      }
    }
  }
</script>
