import { inject } from 'vue'
import { patch } from '@rails/request.js'
import { useSaveStatus } from './useSaveStatus'

export function useBlockUpdate(block) {
  const { setStatus, setIsSaving, setStatusToLastSaved } = useSaveStatus()

  const updateSlides = inject('updateSlides')

  const updateBlock = async (updatePath, body, headers = { 'Content-Type': 'application/json' }, responseKind = 'json') => {
    const startTime = Date.now()
    setStatus('Saving...')
    setIsSaving(true)

    try {
      const response = await patch(updatePath, {
        body,
        headers,
        responseKind
      })

      if (response.ok) {
        return handleSuccessfulUpdate(response, startTime)
      } else {
        handleUpdateError(await response.json)
      }
    } catch (error) {
      handleUpdateError(error)
      throw error
    }
  }

  const handleSuccessfulUpdate = async (response, startTime) => {
    const parsedResponse = await response.json

    updateSlides(parsedResponse.slide)

    const elapsedTime = Date.now() - startTime
    const remainingTime = Math.max(0, 1000 - elapsedTime)

    setTimeout(setStatusToLastSaved, remainingTime)
    setIsSaving(false)

    return parsedResponse
  }

  const handleUpdateError = (error) => {
    console.error('Error updating block:', error)
    setStatus('⚠️ Error saving')
    setIsSaving(false)
  }

  const makeParams = (content, field) => {
    const parts = field.split('.')
    let params = {}
    let current = params

    parts.forEach((key, index) => {
      if (index === parts.length - 1) {
        current[key] = content
      } else {
        current[key] = {}
        current = current[key]
      }
    })

    return { block: params }
  }

  const onBlockUpdate = async (content, field) => {
    if (content instanceof File) {
      const data = new FormData()
      data.append(`block[${field}]`, content)

      return updateBlock(block.update_path, data, {})
    } else {
      return updateBlock(block.update_path, makeParams(content, field))
    }
  }

  return {
    onBlockUpdate
  }
}
