import { capitalize } from '@/helpers'
import { useSidePanelHelpers } from './useSidePanelHelpers'

const DEFAULT_QUESTION_ATTRIBUTES = {
  quiz: {
    answers_attributes: [
      {
        text: "",
        correct: false,
        explanation: ""
      }
    ]
  },
  poll: {
    answers_attributes: [
      {
        text: "",
        static_percentage: 0
      }
    ]
  },
  open: {
    example_answer: ""
  }
}

export function useQuestionHelpers() {
  const { replaceInStack } = useSidePanelHelpers()

  const onAfterCreate = (slides, response) => {
    slides.value = slides.value.map(slide => {
      if (slide.id === response.slide.id) {
        return response.slide
      }
      return slide
    })

    const block = slides.value.find(s => s.id === response.slide.id).blocks.find(b => b.block_type === 'section')

    replaceInStack({
      content: {
        component: 'QuestionForm',
        title: 'Edit question',
        props: { block, question: block.section.questions[block.section.questions.length - 1] }
      }
    })
  }

  const hydrateNewQuestion = (newQuestion, block) => {
    const type = newQuestion.variant_type

    const questionData = {
      questionnaire_id: block.section.id,
      questionnaire_type:"Section",
      question_type: type,
      position: block.section.questions.length + 1,
      position_within_lesson: block.section.questions.length + 1,
      variant_name: type,
      variant_type: `Question::${capitalize(type)}`,
      optional: false,
      text: "",
      variant_attributes: DEFAULT_QUESTION_ATTRIBUTES[type]
    }
    return {
      ...newQuestion,
      ...questionData,
      text: newQuestion.text
    }
  }

  return {
    onAfterCreate,
    hydrateNewQuestion
  }
}
